<template>
    <div>
        <el-dialog :title="`批量修改广告组排期`" 
            :visible="editDateShow" 
            width="500px" 
            @close="close" 
            @opened="openInit"
            :append-to-body="true"
            :modal-append-to-body="false">
            <div>
                <div>
                    <el-row style="margin-bottom:10px">
                        <el-col :span="6" style="line-height:40px">开始时间：</el-col>
                        <el-col :span="18">
                            <el-date-picker
                                v-model="set_start_date"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择开始日期">
                            </el-date-picker>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6" style="line-height:40px">结束时间：</el-col>
                        <el-col :span="18">
                            <el-date-picker
                                v-model="end_date"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择结束日期">
                            </el-date-picker>
                        </el-col>
                    </el-row>
                </div>
                <!-- <el-radio-group  v-model="dateType" @change="selectDateType">
                    <p style="margin-bottom:10px"  v-if="budgetType == 'daily'">
                        <el-radio label="1">
                            <div class="timeChoose" @click="selectStartDate">
                                <el-date-picker
                                    v-if="dateType=='1'"
                                    v-model="set_start_date"
                                    type="datetime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="选择开始时间">
                                    </el-date-picker>
                                    <span class="setTime" v-if="set_start_date==start_date">从今天开始长期投放</span>
                                    <span class="setTime" v-else>从{{set_start_date}}开始长期投放</span>
                            </div>
                        </el-radio>
                    </p>
                    <p>
                        <el-radio label="2">
                            <div class="timeChoose" @click="selectDate">
                                <el-date-picker
                                    is-range
                                    v-model="dateGroup"
                                    range-separator=""
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    type="datetimerange"
                                    start-placeholder="开始时间"
                                    end-placeholder="结束时间"
                                    :default-time="[start_date.split(' ')[1],end_date.split(' ')[1]]"
                                    placeholder="选择时间范围" style="width:220px">
                                </el-date-picker>
                                <span class="setTime" v-if="dateGroup.length !== 2">设置开始和结束日期</span>
                                <span class="setTime" v-else>{{dateGroup[0]}}~{{dateGroup[1]}}</span>
                            </div>
                        </el-radio>
                    </p> 
                </el-radio-group> -->
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="sure">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import {fnCSTToYmd,fnMsToCST} from '@/utils/utils'
    export default {
        props:['editDateShow','budgetType'],
        data() {
            return {
                dateType:'1',
                set_start_date:fnCSTToYmd(new Date()),
                start_date:fnCSTToYmd(new Date()),
                end_date:null,
                dateGroup:[]
            }
        },
        methods: {
            openInit(){
                this.set_start_date = fnCSTToYmd(new Date())
                this.end_date = this.budgetType == 'daily'?null:fnCSTToYmd(new Date(new Date(this.start_date).getTime()+(24*3600*7*1000)))
            },
            close(){
                this.dateType = '1';
                this.set_start_date=this.start_date
                this.dateGroup = [];
                this.$emit('close','editDateShow')
            },
            sure(){
                let {dateType,start_date,end_date,dateGroup} = this;
                if(this.budgetType != 'daily' && !this.end_date){
                    this.$message({
                        type:'warning',
                        message:'设置总预算必须设置结束日期'
                    })
                    return false;
                }
                console.log("{dateType,start_date:this.set_start_date,end_date,dateGroup}",{dateType,start_date:this.set_start_date,end_date,dateGroup})
                this.$emit('get-date',{dateType,start_date:this.set_start_date,end_date,dateGroup})
                this.close();
            },
            selectStartDate(){
                // this.dateType = '1'
            },
            selectDate(){
                this.dateType = '2'
                this.set_start_date=this.start_date
                this.end_date = fnCSTToYmd(new Date(new Date(this.start_date).getTime()+(24*3600*7*1000)));
                console.log(this.end_date);
                this.dateGroup = [this.start_date,this.end_date]
                console.log(this.dateGroup);
            },
            selectDateType(v){
                if(v == '1'){
                    this.end_date = '';
                    this.dateGroup = [];
                }else{
                    this.selectDate()
                }
            }
        },
    }
</script>
<style lang="scss">
    .timeChoose{
        position: relative;
        width: 260px;
        top: -15px;
        cursor: pointer;
        // overflow: hidden;
        // height: 40px;
        .el-range-editor{
            position: absolute;
            opacity: 0;
            z-index: 222;
            left: 20px;
            top: 0;
            height: 18px;
            cursor: pointer;
        }
        .setTime{
            position: absolute;
            left: 20px;
            top: 0;
            cursor: pointer;
            // height: 40px;
            // font-size: 14px;
            // color: #fff;
        }
    }
</style>