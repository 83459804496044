<template>
    <div class="imgList">
        
        <el-drawer title="素材列表" :visible="imgListShow" direction="rtl" @close="close" @opened="open" :with-header="false"
            wrapperClosable size="1150px" :append-to-body="true">
            <div class="headTop">
                <div class="leftHead">
                    <el-form :inline="true" :model="formInline" class="demo-form-inline">
                        <!-- 隐藏 暂时不再使用 -->
                        <el-form-item label="" v-if="false">
                            <el-select v-model="formInline.sourceFroms" placeholder="全部来源" size="mini" multiple
                                style="width:260px" @change="getList">
                                <el-option label="本地上传" value="UPLOAD"></el-option>
                                <el-option label="Facebook媒体库" value="FBADACCOUNT"></el-option>
                                <el-option label="工作流" value="WORKFLOW"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="" v-if="uploadTypes == 'banner'" @change="getList">
                            <div>
                                <el-radio-group v-model="formInline.mediaType" size="mini">
                                    <el-radio-button label="IMAGE">图片</el-radio-button>
                                    <el-radio-button label="VIDEO">视频</el-radio-button>
                                </el-radio-group>
                            </div>
                        </el-form-item>
                        <!-- 隐藏 暂时不再使用-->
                        <el-form-item label="" v-if="false">
                            <el-popover placement="bottom-end" width="700" trigger="click" v-model="chooseAccount">
                                <div class="accountCard">
                                    <div class="accountTop">
                                        <div></div>
                                        <div class="right">
                                            <div class="refresh">
                                                <el-button type="text" icon="el-icon-refresh"
                                                    @click="synBM">同步账号状态</el-button>
                                                <p>{{ date }}</p>
                                            </div>
                                            <div class="search">
                                                <el-input placeholder="广告账户名称或ID" v-model="keyword">
                                                    <el-button slot="append" @click="adaccouts"><i
                                                            class="el-icon-search"></i></el-button>
                                                </el-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accountMain">
                                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
                                            @change="handleCheckAllChange">共选择{{ checkedAccount.length }}个</el-checkbox>
                                        <div style="margin: 15px 0;"></div>
                                        <el-checkbox-group v-model="checkedAccount" @change="handleCheckedCitiesChange"
                                            style="max-height:320px;overflow:auto">
                                            <el-checkbox v-for="account in accountsList" :label="account.id"
                                                :key="account.id" style="margin:10px 0;">
                                                <div class="accountInfo">
                                                    <el-badge is-dot
                                                        :type="account.accountStatus == 1 ? 'success' : 'danger'"><i
                                                            class="el-icon-info"></i></el-badge>
                                                    <div>
                                                        <p class="name">{{ account.name }}</p>
                                                        <span>id:{{ account.id }}</span>
                                                    </div>
                                                </div>
                                            </el-checkbox>
                                        </el-checkbox-group>
                                    </div>
                                    <div class="btnGroup">
                                        <el-button type="primary" size="small" @click="selectAccount">确定</el-button>
                                        <el-button size="small" @click="chooseAccount = false">取消</el-button>
                                    </div>
                                </div>
                                <el-select v-model="formInline.region"
                                    :placeholder="checkedAccount.length ? `共选择${checkedAccount.length}个广告账号` : '不限广告账号'"
                                    slot="reference" no-data-text=" " size="mini">
                                    <!-- <el-option label="区域一" value="shanghai"></el-option>
                                    <el-option label="区域二" value="beijing"></el-option> -->
                                </el-select>
                            </el-popover>

                        </el-form-item>
                    </el-form>
                </div>
                <div class="rightHead">
                    <div class="searchInput">
                        <el-input placeholder="输入关键词搜索" size="mini" v-model="formInline.keyword">
                            <el-button type="text" slot="append" @click="getList"><i class="el-icon-search"></i></el-button>
                        </el-input>
                    </div>
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            <i class="el-icon-sort"></i>
                            <span>{{ formInline.sortDirection ? (formInline.sortDirection == 'ASC' ? '时间升序' : '时间降序') : '默认排序' }}</span>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="">默认排序</el-dropdown-item>
                            <el-dropdown-item command="DES">时间降序</el-dropdown-item>
                            <el-dropdown-item command="ASC">时间升序</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-button class="sort" type="text">

                    </el-button>
                    <el-button class="filter" type="text" @click="filterShow = !filterShow">
                        <i class="el-icon-cold-drink"></i>
                        <span>筛选</span>
                    </el-button>
                </div>
            </div>
            <div class="listCon">
                <div class="list">
                    <div class="listWrap">
                        <ul>
                            <li v-for="(img, index) in imgList" :key="index" @click="choose(img.id, img)">
                                <div class="img" v-if="img.mediaType == 'IMAGE'">
                                    <el-image :src="img.url" fit="contain"></el-image>
                                </div>
                                <div class="img" v-if="img.mediaType == 'VIDEO'">
                                    <video controls :src="img.url" :poster="img.thumbUrl" @play="saveplay(img.id)" :ref="`video${img.id}`"></video>
                                </div>
                                <span :class="[chooseList.indexOf(img.id) >= 0 ? 'active' : '']"><i
                                        class="el-icon-success"></i></span>
                            </li>
                        </ul>
                    </div>
                    <div style="text-align:right">
                        <el-pagination small layout="prev, pager, next" :total="total" :page-size="pageSize"
                            @current-change="handleCurrentChange" :current-page.sync="page">
                        </el-pagination>
                    </div>
                </div>

                <div class="filterCon" v-if="filterShow">
                    <div class="filterHeader">
                        <span>筛选条件</span>
                        <div>
                            <el-button type="text" @click="clear">清空</el-button>
                            <i class="el-icon-circle-close close" @click="closeFilter"></i>
                        </div>
                    </div>
                    <div class="filterMsg">
                        <el-collapse v-model="activeNames">
                            <el-collapse-item title="颜色" name="1">
                                <el-color-picker v-model="formInline.color" @change="getList"></el-color-picker>
                            </el-collapse-item>
                            <el-collapse-item title="形状" name="2">
                                <div style="margin-top: 20px">
                                    <el-radio-group v-model="formInline.sharpe" size="medium" @change="getList">
                                        <el-radio-button label="width" value="width">宽</el-radio-button>
                                        <el-radio-button label="high" value="high">高</el-radio-button>
                                        <el-radio-button label="square" value="square">方</el-radio-button>
                                    </el-radio-group>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="上传时间" name="3" style="text-align:center">
                                <el-date-picker v-model="formInline.timeStart" type="date" placeholder="选择开始时间"
                                    style="margin-bottom:15px" @change="getList" format="yyyy/MM/dd"
                                    value-format="yyyy-MM-dd"></el-date-picker>
                                <el-date-picker v-model="formInline.timeEnd" type="date" placeholder="选择结束时间"
                                    @change="getList" format="yyyy/MM/dd" value-format="yyyy-MM-dd"></el-date-picker>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </div>
            </div>
            <div class="demo-drawer__footer">
                <p></p>
                <div>
                    <el-button @click="close">取 消</el-button>
                    <el-button type="primary" @click="sure">确定</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import { search, accounts } from '@/api/creatAd.js'
import { adaccouts } from "@/api/adManagement.js";
import { synBM } from "@/api/adAssets";
import { mapGetters } from "vuex";
export default {
    props: ['imgListShow', 'uploadTypes', 'selectList', 'limit'],
    data() {
        return {
            list: [],
            formInline: { sourceFroms: ['UPLOAD', 'FBADACCOUNT', 'WORKFLOW'], mediaType: 'IMAGE', keyword: null, sharpe: null, color: '' },
            checkAll: false,
            checkedAccount: [],
            isIndeterminate: true,
            accountAll: [],
            activeNames: '1',
            radio2: '',
            startTime: '',
            endTime: '',
            filterShow: false,
            chooseList: [],
            imgList: [],
            total: 10,
            page: 1,
            pageSize: 20,
            urlList: [],
            accountsList: [],
            keyword: '',
            chooseAccount: false,
            date: ''
        }
    },
    methods: {
        saveplay(curId){
            this.imgList.forEach(item=>{
                if(item.id != curId){
                    this.$refs[`video${item.id}`]?.[0].pause()
                }
            })
        },
        close() {
            this.saveplay('')
            this.$emit('close', 'imgListShow');
            this.urlList = []
            this.chooseList = []
            this.formInline = { sourceFroms: ['UPLOAD', 'FBADACCOUNT'], mediaType: 'IMAGE', keyword: null, sharpe: null, color: '' }
            this.clear()
            // this.urlList = []
        },
        closeFilter() {
            this.filterShow = false;
            this.clear();
            this.getList()
        },
        clear() {
            this.formInline.sharpe = null;
            this.formInline.startTime = null;
            this.formInline.endTime = null;
            this.formInline.color = '';
            this.checkedAccount = [];

        },
        getList() {
            // console.log(this.accountId)
            // console.log(this.formInline);
            this.$showLoading()
            delete this.formInline.sourceFroms
            let request = JSON.parse(JSON.stringify(this.formInline))
            request.timeStart = request.timeStart ? request.timeStart + ' 00:00:00' : ''
            request.timeEnd = request.timeEnd ? request.timeEnd + ' 00:00:00' : ''
            search({ ...request, page: this.page, pageSize: this.pageSize, accountId: this.accountId }).then(res => {
                // console.log(res);
                this.$hideLoading();
                this.imgList = res.data.content;
                this.total = res.data.totalElements
            })
        },
        // 广告同步
        synBM() {
            this.$showLoading();
            synBM({ type: "account" }).then(res => {
                this.$hideLoading();
                if (res.code == 200) {
                    this.$message.success(`同步成功！`);
                    this.adaccouts();
                }
            });
        },
        adaccouts() {
            let params = { keyword: this.keyword, limit: 10000, page: 1, id: this.$store.state.num.selectedNum };
            accounts(params).then(res => {
                if (res.code == 200) {
                    // console.log('123-res',res)
                    if (res.data.list.length) {
                        this.accountsList = res.data.list;
                        this.date = res.data.version_time;
                        this.accountAll = res.data.list.map(v => { return v.id })
                    }
                }
            });
        },
        handleCurrentChange(v) {
            this.page = v;
            this.getList()
        },
        open() {
            if (this.uploadTypes == 'img' || this.uploadTypes == 'banner') {
                this.formInline.mediaType = 'IMAGE'
            } else if (this.uploadTypes == 'video') {
                this.formInline.mediaType = 'VIDEO'
            }
            this.getList();
        },
        // 点击广告账号全选
        handleCheckAllChange(val) {
            this.checkedAccount = val ? this.accountAll : [];
            this.isIndeterminate = false;
        },
        // 点击选择单个广告账号
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.accountsList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.accountsList.length;
            this.getList()
        },
        // 点击选择素材
        choose(id, img) {
            if (this.limit == 1) {
                this.chooseList = [id]
                this.urlList = [img]
            } else {
                if (this.chooseList.indexOf(id) >= 0) {
                    let i = this.chooseList.indexOf(id);
                    // console.log(this.chooseList.indexOf(id));
                    this.chooseList.splice(i, 1)
                    this.urlList.splice(i, 1)
                } else {
                    if(this.chooseList.length >= this.limit) {
                        this.$message({
                            type: 'warning',
                            message: `最大数量为${limit}哦~~`
                        });
                        return false;
                    }
                    this.chooseList.push(id);
                    this.urlList.push(img)
                }
            }

        },
        selectAccount() {
            this.getList();
            this.chooseAccount = false;
        },
        // 排序方式
        handleCommand(v) {
            // console.log(v );
            this.$set(this.formInline, 'sortDirection', v)
            // this.formInline.sortDirection = v
        },
        // 关闭弹窗
        sure() {
            this.$emit('urlList', this.urlList);
            this.close();
        }
    },
    watch: {
        formInline: {
            handler() {
                // this.getList();
            },
            deep: true
        },
        uploadTypes() {

        },
        selectList: {
            handler() {
                this.chooseList = JSON.parse(JSON.stringify(this.selectList))
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters(["accountId"]),
    },
    mounted() {

        // this.adaccouts();
    },
}
</script>
<style lang="scss">
.demo-drawer__footer {
    text-align: center;
    padding: 15px 0;
    // background: #efefef;
    border-top: 1px solid #ddd;
}

.listCon {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: calc(100vh - 150px);

    .list {
        flex: 1;
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
        padding: 10px;

        .listWrap {
            height: calc(100vh - 200px);
            ;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 6px;
                // height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: rgba(0, 0, 0, 0.2);
            }

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                border-radius: 0;
                background: rgba(0, 0, 0, 0.1);
            }

            ul {
                display: flex;
                flex-wrap: wrap;

                li {
                    width: 200px;
                    height: 200px;
                    margin: 10px;
                    position: relative;
                    box-sizing: border-box;
                    padding: 5px;
                    cursor: pointer;
                    border: 1px dashed #efefef;

                    &:hover {
                        span {
                            i {
                                opacity: 0.6;
                            }
                        }
                    }

                    .img {
                        width: 100%;
                        height: 100%;
                    }

                    .el-image {
                        width: 100%;
                        height: 100%;
                    }

                    video {
                        width: 100%;
                        height: 100%;
                    }

                    span {
                        position: absolute;
                        top: 10px;
                        right: 10px;

                        &.active {
                            i {
                                color: #409EFF;
                                opacity: 1;
                            }
                        }

                        i {
                            font-size: 24px;
                            color: #999;
                            opacity: 0;

                        }
                    }
                }
            }
        }


    }

    .filterCon {
        flex: 242px 0 0;
        font-size: 14px;
        border-left: 1px solid #ddd;
        height: 100%;

        .filterMsg {
            padding: 10px;
        }

        .filterHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;

            i {
                font-size: 16px;
                color: #666;
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }
}

.headTop {
    padding: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 0;
    border-bottom: 1px solid #ddd;

    .rightHead {
        display: flex;
        align-items: center;
        font-size: 14px;

        .searchInput {
            display: flex;
            align-items: center;
            margin-right: 5px;

            i {
                font-size: 16px;
                margin-right: 10px;
                margin-left: 10px;
                color: #999;
                cursor: pointer;
            }
        }

        .sort {
            margin: 0 10px;
        }
    }
}

.accountCard {

    // padding: 15px;
    .headTop {
        // padding: 15px;

    }

    .btnGroup {
        padding-top: 10px;
        // background: #efefef;
        border-top: 1px solid #efefef;
        text-align: center;
    }

    .accountTop {
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .right {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .refresh {
            margin-right: 15px;
            font-size: 12px;
            color: #666;
            text-align: center;
        }
    }

    .accountMain {
        margin-top: 15px;
        padding-bottom: 50px;

        .el-checkbox-group {
            display: flex;
            flex-wrap: wrap;

            .el-badge__content.is-fixed.is-dot {
                top: 4px;
                right: 10px;
            }

            .el-checkbox__input {
                margin-top: 3px;
            }

            .accountInfo {
                display: flex;
                align-items: flex-start;

                i {
                    font-size: 18px;
                    color: #409EFF;
                    margin-right: 5px;
                }
            }

            .name {
                width: 240px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
                // font-weight: bold;
                color: #333;
                margin-bottom: 6px;
            }

            span {
                font-size: 12px;
                color: #999;
            }
        }

        .el-checkbox {
            width: 44%;
            margin-bottom: 10px;
            display: flex;
            align-items: flex-start;
        }
    }
}

.el-select-dropdown.el-popper .el-select-dropdown__empty {
    display: none;
}

.imgList {

    // padding: 0 20px;
    .demo-form-inline {}

    .el-drawer.rtl {
        outline: none;

        * {
            outline: none;
        }
    }
}</style>